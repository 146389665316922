<template>
  <el-main>
    <el-tabs v-model="activeName">
      <el-tab-pane v-for="item in tabList" :key="item.step" :label="item.name" :name="item.step"></el-tab-pane>
    </el-tabs>
    <!-- 展示需要收集的数据 -->
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
      <div v-if="activeName == 'first'">
        <el-form-item label="基础设置"></el-form-item>
        <el-form-item label="最低提现金额" prop="">
          <el-input v-model="ruleForm.minimum_withdrawal_amount"></el-input>
        </el-form-item>
        <el-form-item label="结算说明"></el-form-item>
        <el-form-item label="结算说明" prop="">
          <RichText :richTxt="ruleForm.withdrawal_explain" @soninfo="val => (ruleForm.withdrawal_explain = val)"></RichText>
        </el-form-item>
      </div>
      <div v-if="activeName == 'second'">
        <el-form-item label="VIP玩家设置"></el-form-item>
        <el-form-item label="订单销售额:" prop="">
          <el-input v-model="ruleForm.vip_set.order_sales_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">用户有效连接1次，给出的总佣金数</span> -->
        </el-form-item>
      </div>
      <div v-if="activeName == 'third'">
        <el-form-item label="商家奖励设置"></el-form-item>
        <el-form-item label="商家入驻年费:" prop="">
          <el-input v-model="ruleForm.store_set.settle_in_annual_fee">
            <template slot="append">元</template>
          </el-input>
          <!-- <span class="tips">用户产生1笔有效订单，所支付总佣金</span> -->
        </el-form-item>
        <el-form-item label="到店支付结算比例:" prop="">
          <el-input v-model="ruleForm.store_set.profit_retention_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
      </div>
      <div v-if="activeName == 'fourth'">
        <el-form-item label="主理人奖励设置"></el-form-item>
        <el-form-item label="主理人入驻费用:" prop="">
          <el-input v-model="ruleForm.owner_set.settle_in_fee">
            <template slot="append">元</template>
          </el-input>
          <!-- <span class="tips">用户产生1笔有效订单，所支付总佣金</span> -->
        </el-form-item>
        <el-form-item label="利润截留:" prop="">
          <el-input v-model="ruleForm.owner_set.profit_retention_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
      </div>
      <div v-if="activeName == 'fifth'">
        <el-form-item label="合伙人奖励设置"></el-form-item>
        <el-form-item label="合伙人入驻费用:" prop="">
          <el-input v-model="ruleForm.facilitator_set.settle_in_fee">
            <template slot="append">元</template>
          </el-input>
          <!-- <span class="tips">用户产生1笔有效订单，所支付总佣金</span> -->
        </el-form-item>
        <el-form-item label="商家入驻抽佣:" prop="">
          <el-input v-model="ruleForm.facilitator_set.store_in_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="主理人入驻抽佣:" prop="">
          <el-input v-model="ruleForm.facilitator_set.host_in_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="商家交易流水分润抽佣:" prop="">
          <el-input v-model="ruleForm.facilitator_set.store_flow_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="用户交易抽佣:" prop="">
          <el-input v-model="ruleForm.facilitator_set.user_transaction_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="直推推广合伙人:" prop="">
          <el-input v-model="ruleForm.facilitator_set.direct_association_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="间接推广合伙人:" prop="">
          <el-input v-model="ruleForm.facilitator_set.indirect_association_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="直推推广VIP:" prop="">
          <el-input v-model="ruleForm.facilitator_set.direct_VIP_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="间接推广VIP:" prop="">
          <el-input v-model="ruleForm.facilitator_set.indirect_VIP_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="同级分佣:" prop="">
          <el-input v-model="ruleForm.facilitator_set.same_level_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
      </div>
      <div v-if="activeName == 'sixth'">
        <el-form-item label="城市代理奖励设置"></el-form-item>
        <el-form-item label="城市代理入驻费用:" prop="">
          <el-input v-model="ruleForm.agent_set.settle_in_fee">
            <template slot="append">元</template>
          </el-input>
          <!-- <span class="tips">用户产生1笔有效订单，所支付总佣金</span> -->
        </el-form-item>
        <el-form-item label="商家入驻抽佣:" prop="">
          <el-input v-model="ruleForm.agent_set.store_in_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="主理人入驻抽佣:" prop="">
          <el-input v-model="ruleForm.agent_set.host_in_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="商家交易流水分润抽佣:" prop="">
          <el-input v-model="ruleForm.agent_set.store_flow_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="用户交易抽佣:" prop="">
          <el-input v-model="ruleForm.agent_set.user_transaction_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="直推推广合伙人:" prop="">
          <el-input v-model="ruleForm.agent_set.direct_association_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="间接推广合伙人:" prop="">
          <el-input v-model="ruleForm.agent_set.indirect_association_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="直推推广VIP:" prop="">
          <el-input v-model="ruleForm.agent_set.direct_VIP_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="间接推广VIP:" prop="">
          <el-input v-model="ruleForm.agent_set.indirect_VIP_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="同级分佣:" prop="">
          <el-input v-model="ruleForm.agent_set.same_level_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="所在区域商家抽佣:" prop="">
          <el-input v-model="ruleForm.agent_set.area_store_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
      </div>
      <div v-if="activeName == 'seventh'">
        <el-form-item label="联合创始人奖励设置"></el-form-item>
        <el-form-item label="联合创始人入驻费用:" prop="">
          <el-input v-model="ruleForm.unite_set.settle_in_fee">
            <template slot="append">元</template>
          </el-input>
          <!-- <span class="tips">用户产生1笔有效订单，所支付总佣金</span> -->
        </el-form-item>
        <el-form-item label="商家入驻抽佣:" prop="">
          <el-input v-model="ruleForm.unite_set.store_in_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="主理人入驻抽佣:" prop="">
          <el-input v-model="ruleForm.unite_set.host_in_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="商家交易流水分润抽佣:" prop="">
          <el-input v-model="ruleForm.unite_set.store_flow_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="用户交易抽佣:" prop="">
          <el-input v-model="ruleForm.unite_set.user_transaction_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="直推推广合伙人:" prop="">
          <el-input v-model="ruleForm.unite_set.direct_association_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="间接推广合伙人:" prop="">
          <el-input v-model="ruleForm.unite_set.indirect_association_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="直推推广VIP:" prop="">
          <el-input v-model="ruleForm.unite_set.direct_VIP_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="间接推广VIP:" prop="">
          <el-input v-model="ruleForm.unite_set.indirect_VIP_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="同级分佣:" prop="">
          <el-input v-model="ruleForm.unite_set.same_level_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
        <el-form-item label="所在区域商家抽佣:" prop="">
          <el-input v-model="ruleForm.unite_set.area_store_commission_percentage">
            <template slot="append">%</template>
          </el-input>
          <!-- <span class="tips">超过扫码点餐佣金起算金额的订单，才会产生点餐佣金</span> -->
        </el-form-item>
      </div>
      <div v-if="activeName == 'eighth'">
        <el-form-item label-width="100px" label="" prop="">
          <el-button type="primary" size="small" @click="addFullRefund">+ 添加等级</el-button>
        </el-form-item>
        <el-form-item label-width="100px" v-for="(item, index) in fullRefund" :key="index" :label="'V' + item.level_id">
          <div class="levelBox">
            <div class="text">满</div>
            <el-input @input="() => changeInput(item)" v-model="item.condition">
              <template slot="append">元</template>
            </el-input>
            <div class="text">抽佣</div>
            <el-input @input="() => changeInput(item)" v-model="item.ratio">
              <template slot="append">%</template>
            </el-input>
            <i @click="deleteLevel(index)" v-if="fullRefund.length > 1" class="el-icon-close deleteLevel"></i>
            <el-button @click="saveFullRefund(item)" v-if="item.isChange" type="primary" size="mini">保存</el-button>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <Preservation v-if="activeName != 'eighth'" @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
import _ from 'lodash';
export default {
  components: {
    Preservation,
    RichText,
  },
  data() {
    return {
      activeName: 'first',
      tabList: [
        { name: '基础设置', step: 'first' },
        { name: 'VIP玩家', step: 'second' },
        { name: '商家奖励', step: 'third' },
        { name: '主理人奖励', step: 'fourth' },
        { name: '合伙人奖励', step: 'fifth' },
        { name: '城市代理奖励', step: 'sixth' },
        { name: '联合创始人奖励', step: 'seventh' },
        { name: '消费全返活动', step: 'eighth' },
      ],
      ruleForm: {
        minimum_withdrawal_amount: '',
        withdrawal_explain: '',
        // vip设置
        vip_set: {
          order_sales_percentage: '',
        },
        // 商家设置
        store_set: {
          settle_in_annual_fee: '',
          settlement_ratio_percentage: '',
        },
        // 主理人设置
        owner_set: {
          settle_in_fee: '',
          profit_retention_percentage: '',
        },
        // 合伙人设置
        facilitator_set: {
          settle_in_fee: '',
          store_in_commission_percentage: '',
          store_flow_commission_percentage: '',
          host_in_commission_percentage: '',
          user_transaction_commission_percentage: '',
          direct_association_commission_percentage: '',
          indirect_association_commission_percentage: '',
          direct_VIP_commission_percentage: '',
          indirect_VIP_commission_percentage: '',
          same_level_commission_percentage: '',
        },
        // 代理商设置
        agent_set: {
          settle_in_fee: '',
          store_in_commission_percentage: '',
          store_flow_commission_percentage: '',
          host_in_commission_percentage: '',
          user_transaction_commission_percentage: '',
          direct_association_commission_percentage: '',
          indirect_association_commission_percentage: '',
          direct_VIP_commission_percentage: '',
          indirect_VIP_commission_percentage: '',
          same_level_commission_percentage: '',
          area_store_commission_percentage: '',
        },
        // 联合创始人设置
        unite_set: {
          settle_in_fee: '',
          store_in_commission_percentage: '',
          store_flow_commission_percentage: '',
          host_in_commission_percentage: '',
          user_transaction_commission_percentage: '',
          direct_association_commission_percentage: '',
          indirect_association_commission_percentage: '',
          direct_VIP_commission_percentage: '',
          indirect_VIP_commission_percentage: '',
          same_level_commission_percentage: '',
          area_store_commission_percentage: '',
        },
      },
      // 消费全返数据
      fullRefund: [],
      cloneFullRefund: [],
      fullRefundMaxLevel: 0,
      rules: {},
      json_key: ['vip_set', 'store_set', 'owner_set', 'facilitator_set', 'agent_set', 'unite_set'],
    };
  },
  created() {
    this.getSet();
    this.getFullRefundInfo();
  },
  methods: {
    // 保存分级
    saveFullRefund(data) {
      if (data.ratio === '' || data.condition === '') {
        this.$message.error('请将该项数据填写完整');
        return;
      }
      if (Number(data.ratio) > 100) {
        this.$message.error('比例必须小于100');
        return;
      }
      let obj = {
        condition: data.condition,
        ratio: data.ratio,
        level_id: data.level_id,
      };
      if (!data.is_add) {
        obj.id = data.id;
      }
      this.$axios.post(this.$api.serviceProvider.setZujuUserLevel, obj).then(res => {
        if (res.code == 0) {
          this.$message.success(data.is_add ? '添加成功' : '编辑成功');
          this.$set(data, 'isChange', false);
          if (data.is_add) {
            data.id = res.result;
            delete data.is_add;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 输入框发生改变时
    changeInput(data) {
      if (data.isChange) return;
      data.isChange = true;
    },
    deleteLevel(index) {
      let data = this.fullRefund[index];
      if (data.is_add) {
        this.fullRefund.splice(index, 1);
        this.$message.success('删除成功');
        return;
      }
      this.$axios
        .post(this.$api.serviceProvider.delZujuUserLevel, {
          id: data.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.fullRefund.splice(index, 1);
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    addFullRefund() {
      this.fullRefundMaxLevel = this.getMaxLevel() + 1;
      this.fullRefund.push({ condition: '', ratio: '', isChange: true, level_id: this.fullRefundMaxLevel, is_add: true });
    },
    getFullRefundInfo() {
      this.$axios.post(this.$api.serviceProvider.zujuUserLevel).then(res => {
        if (res.code === 0) {
          // 备份原始数据
          this.cloneFullRefund = _.cloneDeep(res.result);
          this.fullRefund = res.result;
          this.fullRefundMaxLevel = this.getMaxLevel(1);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    getMaxLevel(style) {
      let idArr = [];
      this.fullRefund.map(item => {
        idArr.push(item.level_id);
        if (style) item.isChange = false;
      });
      return Math.max(...idArr);
    },
    // 获取设置
    getSet() {
      this.$axios.post(this.$api.set.TwitterSetInfo).then(res => {
        if (res.code === 0) {
          for (const key in this.ruleForm) {
            let isJSON = this.json_key.findIndex(i => i == key);
            this.ruleForm[key] = res.result[isJSON == -1 ? key : `${key}s`];
          }
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 点击保存
    Preservation() {
      if (!this.checkData()) {
        this.$message.warning('请检查各个选项的数据是否填写完整');
        return;
      }
      let obj = _.cloneDeep(this.ruleForm);
      this.$axios.post(this.$api.set.TwitterSet, obj).then(res => {
        if (res.code == 0) {
          this.$message.success('设置成功');
          this.getSet();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 保存前校验数据
    checkData() {
      let flag = true;
      for (const key in this.ruleForm) {
        if (this.ruleForm[key] === '') {
          console.log(key, '没填');
          flag = false;
          break;
        }
        if (typeof this.ruleForm[key] === 'object') {
          let sonFlag = true;
          for (const son in this.ruleForm[key]) {
            if (this.ruleForm[key][son] === '') {
              sonFlag = false;
              break;
            }
          }
          flag = sonFlag;
        }
      }
      return flag;
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  padding-bottom: 80px;

  .tips {
    font-size: 12px;
    color: #999;
    font-weight: 400;
    margin-left: 20px;
  }

  .el-input {
    width: 400px;
  }
  .levelBox {
    display: flex;
    align-items: center;
    .el-input {
      width: 140px;
    }
    .text {
      margin: 0 16px;
    }
    .deleteLevel {
      font-size: 20px;
      margin: 0 16px;
    }
  }
}
</style>
